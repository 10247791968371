
    
<template>
  <div>
    <CModal title="Modal Uji Kom" color="primary" :show.sync="modalUjiKom" size="xl">
      <CDataTable
        :items="computedItems"
        :fields="fields"
        hover
        small
        border
        :loading="loading"
        :pagination="true"
        :items-per-page="10"
      >
        <template #upload_dokumen="{item}">
          <td>
            <CButton
              color="success"
              square
              size="sm"
              class="ml-1"
              variant="outline"
              @click="downloadFile(item)"
            >Download File</CButton>
          </td>
        </template>
      </CDataTable>
    </CModal>
  </div>
</template>
<script>
const fields = [
  { key: "number", label: "No" },
  { key: "nip_nama", label: "NIP - Nama" },
  { key: "tempat", label: "Tempat" },
  { key: "tgl", label: "Tanggal" },
  { key: "upload_dokumen", label: "Download" },
];
export default {
  name: "ModalUjiKom",
  data() {
    return {
      modalUjiKom: false,
      loading: false,
      fields: fields,
      items: [],
    };
  },
  computed: {
    computedItems() {
      var number = 1;
      return this.items.map((item) => {
        return {
          ...item,
          number: number++,
          nip_nama: item.nip + " - " + item.d_identitas.nama,
        };
      });
    },
  },
  methods: {
    toggle(item) {
      this.items = [];
      this.modalUjiKom = !this.modalUjiKom;
      this.loadData(item);
    },
    downloadFile(item) {
      window.open(item.upload_dokumen, "_blank");
    },
    async loadData(item) {
      try {
        this.loading = true;
        let data = await this.$store.dispatch("uji_kompetensi/by_pegawai", {
          nip: item.nip,
        });
        this.items = data;
      } catch (ex) {
        this.items = [];
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<template>
  <div>
    <CCard  accentColor="primary">
      <CCardHeader>
        <slot name="header">
          <CIcon name="cil-grid" />
          {{title}}
        </slot>
      </CCardHeader>
      <CCardBody>
        <CRow v-show="!formVisibility">
          <CCol md="12">
            <div role="group" class="form-group">
              <label class>OPD</label>
              <v-select
                v-model="form.id_opd"
                :options="optionsOPD"
                label="nama_opd"
                :reduce="opd => opd.id_opd"
                :filterable="true"
                @search="onSearchOPD"
              ></v-select>
            </div>
            <div role="group" class="form-group">
              <label class>Unit Kerja</label>
              <v-select
                v-model="selectedUnitKerja"
                :options="optionsUnitKerja"
                label="nama_unit_kerja"
                :reduce="unit_kerja => unit_kerja"
                :filterable="true"
                @search="onSearchUnitKerja"
                @input="onChangesUnitKerja($event)"
              ></v-select>
            </div>
            <CInput label="Jabatan" placeholder="Jabatan" v-model="form.jabatan"></CInput>
          </CCol>
        </CRow>
        <CButtonGroup size="sm">
          <CButton color="info" v-show="!formVisibility" @click="loadData()">
            <CIcon name="cil-search" />Cari
          </CButton>
          <CButton color="warning" v-show="!formVisibility" @click="reset()">
            <CIcon name="cil-loop-circular" />Reset
          </CButton>
          <CButton color="success" v-show="formVisibility"  @click="formVisibility = !formVisibility">
            <CIcon :name="icon_button" />
            Kembali
          </CButton>
        </CButtonGroup>
        <hr />
        <CCollapse :show="formVisibility">
          <form-input @done="resetTabel" ref="formInput" />
        </CCollapse>
        <CDataTable
          :items="computedItems"
          :fields="fields"
          hover
          small
          border
          v-show="!formVisibility"
          :loading="loading"
        >
          <template #show_details="{item, index}">
            <td class="py-2">
              <CButton
                color="primary"
                variant="outline"
                square
                size="sm"
                class="ml-1"
                @click="toggleDetails(item, index)"
              >{{Boolean(item._toggled) ? 'Tutup' : 'Edit'}}</CButton>
            </td>
          </template>
        </CDataTable>
        <CPagination
          :activePage.sync="page"
          :pages.sync="totalPage"
          size="sm"
          align="end"
          v-show="!formVisibility"
        />
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import formInput from "./form";
import VSelect from "vue-select";
import _ from "lodash";
const fields = [
  { key: "number", label: "No" },
  { key: "unit_kerja", label: "Unit Kerja" },
  { key: "jabatan", label: "Jabatan" },
  { key: "kelas_jabatan", label: "Kelas Jabatan" },
  { key: "abk", label: "ABK" },
  { key: "persediaan_pegawai", label: "Persediaan Pegawai" },
  { key: "selisih", label: "Selisih" },
  {
    key: "show_details",
    label: "Action",
    sorter: false,
    filter: false,
  },
];
export default {
  name: "MonitoringJabatanFungsional",
  components: {
    formInput,
    VSelect,
  },
  data() {
    return {
      items: [],
      fields,
      details: [],
      collapseDuration: 0,
      formVisibility: false,
      loading: false,
      page: 1,
      totalPage: 0,
      optionsInstansi: [],
      optionsOPD: [],
      optionsUnitKerja: [],
      optionsJabatanUmum: [],
      form: {
        id_opd: null,
        id_unit_kerja: null,
        nama_unit_kerja: null,
        jabatan: null,
      },
      selectedUnitKerja: [],
    };
  },
  watch: {
    page: function () {
      this.loadData();
    },
  },
  computed: {
    computedItems() {
      var number = 1;
      let total_pages = 10 * (this.page - 1);
      return this.items.map((item) => {
        return {
          ...item,
          number: number++ + total_pages,
        };
      });
    },
    title() {
      return this.formVisibility
        ? "Form Monitoring Jabatan Fungsional"
        : "Tabel Monitoring Jabatan Fungsional";
    },
    icon_button() {
      return !this.formVisibility ? "cil-plus" : "cil-arrow-left";
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    reset() {
      this.form.id_opd = null;
      this.form.id_unit_kerja = null;
      this.form.nama_unit_kerja = null;
      this.selectedUnitKerja= [];
      this.form.jabatan = null;
      this.page = 1;
      this.loadData();
    },
    async loadData() {
      try {
        this.loading = true;
        let data = await this.$store.dispatch(
          "monitoring_jabatan_fungsional/index",
          {
            page: this.page,
            data: this.form,
          }
        );
        this.items = data.data;
        this.totalPage = data.last_page;
      } catch (ex) {
        this.items = [];
      } finally {
        this.loading = false;
      }
    },
    resetTabel() {
      this.formVisibility = false;
      this.loadData();
    },
    toggleDetails(item) {
      // this.$set(this.items[index], "_toggled", !item._toggled);
      this.formVisibility = true;
      this.$refs.formInput.populateInput(item);
      this.collapseDuration = 300;
      this.$nextTick(() => {
        this.collapseDuration = 0;
      });
    },
    async onSearchOPD(search, loading) {
      loading(true);
      await this.searchOPD(search, this);
      loading(false);
    },
    searchOPD: _.debounce(async (search, vm) => {
      let options = await vm.$store.dispatch(
        "kualifikasi_jabatan_struktural/autocomplete_opd",
        search
      );
      if (search) vm.optionsOPD = options;
    }, 300),
    async onSearchUnitKerja(search, loading) {
      loading(true);
      await this.searchUnitKerja(search, this);
      loading(false);
    },
    searchUnitKerja: _.debounce(async (search, vm) => {
      let options = await vm.$store.dispatch("kualifikasi_jabatan_struktural/autocomplete_opd_unit_kerja", {
        search: search,
        id_opd: vm.form.id_opd
      });
      if (search) vm.optionsUnitKerja = options;
    }, 300),
    onChangesUnitKerja(val){
      this.form.id_unit_kerja = val.id_unit_kerja;
      this.form.nama_unit_kerja = val.nama_unit_kerja;
    },
  },
};
</script>